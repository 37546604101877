import { createSlice } from '@reduxjs/toolkit'
import loan from '../data/loan.json'
import _ from "lodash";
import {Simulate} from "react-dom/test-utils";

let minLoan:any = {};
let tblHealthy:any = {};
let tblUnhealthy:any = {};
let tblUnhealthyJoint:any = {};
let tblDate = "";
let tblMaxHousePrice = 1000000;
let dataLoaded = false;

async function loadData() {
  let currentType = 'mab';

  let minData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/min');
  let healthyData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/healthy');
  let unhealthyData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/unhealthy');
  let unhealthyJointData = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/unhealthyJoint');
  let serverDate = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/date');
  let maxHousePrice = await fetch('https://ltvtoolrates.moreish.host/rates/' + currentType + '/max');

  minLoan = await(minData).json();
  tblHealthy = await(healthyData).json();
  tblUnhealthy = await(unhealthyData).json();
  tblUnhealthyJoint = await(unhealthyJointData).json();
  tblDate = await(serverDate).text();
  tblMaxHousePrice = parseInt(await(maxHousePrice).text());

  dataLoaded = true;
}

export const initialState = {
  loading: false,
  hasErrors: false,
  loanToValue: {
    minimum: 0,
    healthy: 0,
    unhealthy: 0,
    minimumPercent: 0,
    healthyPercent: 0,
    unhealthyPercent: 0,
    date: '00/00/00',
    maxHousePrice: 1000000,
    homeCost: 0
  },
}

const loanToValueSlice = createSlice({
  name: 'loanToValue',
  initialState,
  reducers: {
    calculateLoanRequest: (state) => {
      state.loading = true
    },
    calculateLoanSuccess: (state, { payload }) => {
      state.loanToValue = payload
      state.loading = false
      state.hasErrors = false
    },
    calculateLoanFailure: (state) => {
      state.loading = false
      state.hasErrors = true
    },
  },
});

/*** Three actions generated from the slice ***/
export const { calculateLoanRequest, calculateLoanSuccess, calculateLoanFailure } = loanToValueSlice.actions

/*** A Selector ***/
export const loanToValueSelector = (state: any) => state.loanToValue

/*** The reducer ***/
export default loanToValueSlice.reducer

/*** Asynchronous thunk action ***/
export function calculateLoan(age: any, homecost: any, isJoint: boolean) {
  return async (dispatch: any) => {
    dispatch(calculateLoanRequest())

    if(dataLoaded === false)
    {
      await loadData()
    }

    const arr = loan.loan.filter(person => person?.age == age);

    // const arr1 = _.find(loan.loan, { age: age });
    let currentAge = age[0];

    let minimumPercent = Number(minLoan[currentAge]);
    let unhealthyPercent = Number(tblHealthy[currentAge]);
    let healthyPercent = Number(tblUnhealthy[currentAge]);

    if(isJoint)
    {
      healthyPercent = Number(tblUnhealthyJoint[currentAge]);
    }

    const homeValue = homecost ? homecost : 1;

    let minimumLoan = ((homeValue * minimumPercent)/100).toFixed(0);
    let healthyLoan = ((homeValue * healthyPercent)/100).toFixed(0);
    let unhealthyLoan = ((homeValue * unhealthyPercent)/100).toFixed(0);

    try {
      let data;

      if(homeValue < 70000) {
        data = await {
          minimum: 0,
          healthy: 0,
          unhealthy: 0,
          minimumPercent: 0,
          healthyPercent: 0,
          unhealthyPercent: 0,
          date: tblDate,
          maxHousePrice: tblMaxHousePrice,
          homeCost: homeValue
        };
      }
      else{
        data = await {
          minimum: minimumLoan,
          healthy: healthyLoan ? healthyLoan : 0,
          unhealthy: unhealthyLoan ? unhealthyLoan : 0,
          minimumPercent: minimumPercent ? minimumPercent.toFixed(1) : 5.00,
          healthyPercent: healthyPercent ? healthyPercent.toFixed(1) : 29.50,
          unhealthyPercent: unhealthyPercent ? unhealthyPercent.toFixed(1) : 43.60,
          date: tblDate,
          maxHousePrice: tblMaxHousePrice,
          homeCost: homeValue
      };
    }

      dispatch(calculateLoanSuccess(data))
    } catch (error) {
      dispatch(calculateLoanFailure())
    }
  }
}
