import { useSelector } from 'react-redux'
import { loanToValueSelector } from '../slices/loanToValue'
import ReactTooltip from "react-tooltip"
import NumberFormat from 'react-number-format'

const Result = () => {
    const maxPctValue = 59 * 1.3;
    const maxCoinsHeight = 170;

    const { loanToValue } = useSelector(loanToValueSelector);

    return (
        <>
            <div className="loan-graphics-row">
                {(loanToValue.maxHousePrice < loanToValue.homeCost) ? (
                    <div style={{color: 'red', width: '100%', textAlign: 'center', paddingBottom: '24px'}}>
                        The property value entered is above the maximum allowed for this calculator. If your property is currently valued at more than £{ loanToValue.maxHousePrice.toLocaleString() } please contact us for a personalised illustration.
                    </div>
                ) : (
                    <div style={{display: 'none'}}>!</div>
                )}
                <div className="loan-percentage-col ">
                    <div className="loan-per-sec">
                        <div className="loan-per-img"><img src={process.env.PUBLIC_URL +'/img/min-img.svg'} /></div>
                        <div className="loan-per-per">
                            <h3>{`${loanToValue.minimumPercent}%`}</h3>
                            <div className="coins" style={{ height: '25px', background: "url('" + process.env.PUBLIC_URL + "/img/coins-under-bk.png') 0px 0px no-repeat;" }} >
                                <img className="coins-img" src={process.env.PUBLIC_URL +'/img/max-img-coin.svg'} alt="" />
                                <img className="coins-bg" src={process.env.PUBLIC_URL +'/img/coins-under-bk.png'} alt="" />
                            </div>
                        </div>
                    </div>
                    <p> <b>Minimum Loan </b> facility* </p>
                    <p className="price">
                        <NumberFormat
                            value={(loanToValue.minimum < 10000) ? 10000 : loanToValue.minimum}
                            className="foo"
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'£ '}
                        />
                    </p>

                </div>
                <div className="loan-percentage-col ">
                    <div className="loan-per-sec">
                        <div className="loan-per-img"><img src={process.env.PUBLIC_URL +'/img/max-img.svg'} /></div>
                        <div className="loan-per-per">
                            <h3>{`${loanToValue.unhealthyPercent}%`}</h3>
                            <div className="coins" style={{ height: loanToValue.unhealthyPercent * maxCoinsHeight / maxPctValue, background: "url('" + process.env.PUBLIC_URL + "/img/coins-under-bk.png') 0px 0px no-repeat;" }} >
                                <img className="coins-img" src={process.env.PUBLIC_URL +'/img/max-img-coin.svg'} alt="" />
                                <img className="coins-bg" src={process.env.PUBLIC_URL +'/img/coins-under-bk.png'} alt="" />
                            </div>
                        </div>
                    </div>

                    <p> <b> Average Loan </b> on standard terms </p>
                    <p className="price">
                        <NumberFormat
                            value={loanToValue.unhealthy}
                            className="foo"
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'£ '}
                        />
                    </p>
                    <ReactTooltip id="registerTip" place="bottom" effect="solid">
                        <p>
                            It is possible to reach much higher LTVs based on the medical background of the
                            client. A set of simple medical and lifestyle-related questions are used to work out
                            an enhanced LTV rate. Actual rates will vary according to the specific medical
                            underwriting of each client.
                        </p>
                    </ReactTooltip>
                </div>
                <div className="loan-percentage-col ">
                    <div className="loan-per-sec">
                        <div className="loan-per-img"><img src={process.env.PUBLIC_URL +'/img/med-img.svg'} /></div>
                        <div className="loan-per-per">
                            <h3>{`${loanToValue.healthyPercent}%`}</h3>
                            <div className="coins" style={{ height: loanToValue.healthyPercent * maxCoinsHeight / maxPctValue, background: "url('" + process.env.PUBLIC_URL + "/img/coins-under-bk.png') 0px 0px no-repeat;" }} >
                                <img className="coins-img" src={process.env.PUBLIC_URL +'/img/max-img-coin.svg'} alt="" />
                                <img className="coins-bg" src={process.env.PUBLIC_URL +'/img/coins-under-bk.png'} alt="" />
                            </div>
                        </div>
                    </div>

                    <p> <b> Maximum Loan </b> on standard terms </p>
                    {/* <p className="price"> {`£${loanToValue.healthy}`}</p> */}
                    <p className="price">
                        <NumberFormat
                            value={loanToValue.healthy}
                            className="foo"
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'£ '}
                        />
                    </p>

                </div>
                <small className ="bottom-text">*subject to minimum initial release of £10,000</small><small className ="bottom-text">The figures are illustrative only, based on LTVs published as @ {loanToValue.date}. Actual LTVs available at the time of application will depend on the product chosen. They may be higher or lower than the figures displayed above and subject to change. Average LTVs are based on data from Key Group and show the typical amount released at each age.</small>
            </div>
        </>
    )
}

export default Result
